import { appInject, appInjectable } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { appI18n } from '@core/translation/i18n';
import { DI_TOKENS } from '@shared/constants/di';
import { Locale } from '@shared/translation/locales';
import { IDataResetHandler } from '@shared/types/data-reset-handler';
import { ILocalesVM } from '@shared/types/locales-vm';
import { IStorageService, StorageType } from '@shared/types/storage-service';
import { dateUtils } from '@shared/utils/date';
import { en, te } from 'make-plural/plurals';

import { messages as enMessages } from '../../locales/en/messages';
import { messages as testMessages } from '../../locales/te/messages';
import 'moment/locale/te';

const STORED_LOCALE_KEY = 'STORED_LOCALE_KEY';

@appInjectable()
export class LocalesViewModel implements ILocalesVM, IDataResetHandler {
  private storageService = appInject<IStorageService>(DI_TOKENS.storageService);
  private _locale: Locale;

  constructor() {
    this._locale = this.getStoredLocale();

    appMakeObservable(this, {
      _locale: appObservable,
    });
  }

  initialize() {
    appI18n.load({
      [Locale.English]: enMessages,
      [Locale.Test]: testMessages,
    });
    appI18n.loadLocaleData({
      [Locale.English]: { plurals: en },
      [Locale.Test]: { plurals: te },
    });
    this.setLocale(this._locale);
  }

  get locale(): Locale {
    return this._locale;
  }

  private setLocale(locale: Locale) {
    this._locale = locale;
    appI18n.activate(locale as string);
    dateUtils.locale(locale as string);
    this.setStoredLocale(this._locale);
  }

  toggleLocales() {
    const locales = Object.values(Locale);
    const currentIndex = locales.indexOf(appI18n.locale as Locale);
    const nextIndex = (currentIndex + 1) % locales.length;
    const nextLocale = locales[nextIndex];

    this.setLocale(nextLocale as Locale);
  }

  private getStoredLocale = (): Locale => {
    const storedLocale = this.storageService.get(StorageType.localStorage, STORED_LOCALE_KEY);
    if (storedLocale) {
      return storedLocale as Locale;
    }
    return Locale.English;
  };

  private setStoredLocale = (locale: Locale) => {
    this.storageService.set(StorageType.localStorage, STORED_LOCALE_KEY, locale);
  };

  //Erase data protocol - happens on logout
  onDataReset = () => {
    this.storageService.remove(StorageType.localStorage, STORED_LOCALE_KEY);
    this._locale = this.getStoredLocale();
  };
}
