import { appInject, appInjectable } from '@core/di/utils';
import { BaseService } from '@core/services/base';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import {
  NotificationListModel,
  NotificationListQuery,
} from '@shared/models/notifications/list-model';
import { NotificationTopic } from '@shared/models/notifications/notification-topic';
import { IDataResetHandler } from '@shared/types/data-reset-handler';
import { HeaderComponentType, ILayoutVM } from '@shared/types/layout-service';
import { IStorageService, StorageType } from '@shared/types/storage-service';

@appInjectable()
export class LayoutViewModel extends BaseService implements ILayoutVM, IDataResetHandler {
  private storageService = appInject<IStorageService>(DI_TOKENS.storageService);
  private _publicHeaderContainerComponent: HeaderComponentType;
  private _notifications: NotificationListModel[] = [];
  private _loadingIsShown = false;

  constructor() {
    super();

    this._publicHeaderContainerComponent = null;

    appMakeObservable(this, {
      _publicHeaderContainerComponent: appObservable,
      _notifications: appObservable,
      _loadingIsShown: appObservable,
    });
  }

  get isGlobalLoadingShown(): boolean {
    return this._loadingIsShown;
  }

  get hasNotifications(): boolean {
    return this._notifications.length > 0;
  }

  get notifications(): NotificationListModel[] {
    return this._notifications.filter((notification) => !this.isNotificationHidden(notification));
  }

  showGlobalLoading() {
    this._loadingIsShown = true;
  }

  hideGlobalLoading() {
    this._loadingIsShown = false;
  }

  addNotification(notification: NotificationListModel) {
    const present = (n: NotificationListModel) => n.asJson.id === notification.asJson.id;

    if (!this._notifications.some(present)) {
      this._notifications.push(notification);
    }
  }

  removeNotification = (id: NotificationListQuery['id']) => {
    this._notifications = this._notifications.filter((n) => n.asJson.id !== id);
  };

  hideNotification = (id: NotificationListQuery['id']) => {
    const key = this.notificationStorageKey(id);
    this.storageService.set(StorageType.localStorage, key, true);
    this._notifications = [...this._notifications];
  };

  get publicHeaderControls(): HeaderComponentType {
    return this._publicHeaderContainerComponent;
  }

  setPublicHeaderControls(controls: HeaderComponentType): void {
    this._publicHeaderContainerComponent = controls;
  }

  private isNotificationHidden(notification: NotificationListModel): boolean {
    const hidden = this.storageService.get(
      StorageType.localStorage,
      this.notificationStorageKey(notification.asJson.id),
    );
    return !!hidden;
  }

  private notificationStorageKey(id: NotificationListQuery['id']): string {
    return `notification.${id}.hidden`;
  }

  //Erase data protocol - happens on logout
  onDataReset = () => {
    Object.values(NotificationTopic).forEach((id: NotificationListQuery['id']) => {
      const key = this.notificationStorageKey(id);
      this.storageService.remove(StorageType.localStorage, key);
    });
  };
}
