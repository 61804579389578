import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { ProductModel } from '@shared/models/products/product-model';
import { IProductsService } from '@shared/types/products-service';
import { IProductsVM } from '@shared/types/products-vm';

@appInjectable()
export class ProductsViewModel implements IProductsVM {
  private productsService = appInject<IProductsService>(DI_TOKENS.productsService);
  private productsList: Array<ProductModel> = [];
  private productsMap = new Map<string, ProductModel>();

  constructor() {}

  downloadProducts = async (): Promise<void> => {
    this.productsList = await this.productsService.getList();
    this.productsList.forEach((product) => {
      this.productsMap.set(product.asJson.name, product);
    });
  };

  get products() {
    return this.productsList;
  }

  getProductByCurrency(currency: string): ProductModel | undefined {
    return this.productsMap.get(currency);
  }

  getProductTypeByCurrency(currency: string): string {
    return this.getProductByCurrency(currency)?.asJson.type || '';
  }

  getBaseCurrencyByCurrency(currency: string): string {
    return this.getProductByCurrency(currency)?.asJson.baseCurrency || '';
  }
}
