import { appInjectable } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { OrderDetailsType } from '@shared/enums/order-details-type';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';

@appInjectable()
export class SmartCoinDetailsDialogViewModel implements ISmartCoinDetailsDialogViewModel {
  private _orderId: string | null = null;
  private _isOpen: boolean = false;
  private _type: OrderDetailsType;
  private _handle3DSecure: boolean = false;

  constructor() {
    appMakeObservable(this, {
      _orderId: appObservable,
      _isOpen: appObservable,
      _handle3DSecure: appObservable,
    });
  }

  get orderId(): string | null {
    return this._orderId;
  }

  get type(): OrderDetailsType {
    return this._type;
  }

  get isOpenDetails() {
    return this._isOpen && !this._handle3DSecure;
  }

  openDialog(orderId: string, orderDetailsType: OrderDetailsType) {
    this._orderId = orderId;
    this._isOpen = true;
    this._type = orderDetailsType;
  }

  closeDialog() {
    this._isOpen = false;
    this._orderId = null;
  }

  hideDialog() {
    this._isOpen = false;
  }

  showDialog() {
    this._isOpen = true;
  }

  start3DSecure() {
    this._handle3DSecure = true;
  }

  finish3DSecure() {
    this._handle3DSecure = false;
  }
}
