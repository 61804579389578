import React from 'react';

import { appInject } from '@core/di/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Loading } from '@shared/components/loading';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { useNavigate } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { IUsersService } from '@shared/types/users-service';
import { useQuery } from 'react-query';

import MaintenanceSVG from './img/maintenance.svg';
import { styles } from './maintenance-page.syles';

export interface MaintenancePageProps extends AppWithStyles<typeof styles> {}

const MaintenancePageComponent: React.FC<MaintenancePageProps> = ({ classes }) => {
  const navigate = useNavigate();
  const usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  const fetchUserDataQuery = useQuery(
    ['download-user-data'],
    () => usersService.getMyUserDetails(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  if (fetchUserDataQuery.isLoading) {
    return (
      <div className={classes.root}>
        <Loading size={70} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <div className={classes.title}>{t`Maintenance`}</div>
        <div className={classes.imageBlock}>
          <img className={classes.image} src={String(MaintenanceSVG)}></img>
        </div>
        <div className={classes.text}>
          <p>
            {t`Hi`} {fetchUserDataQuery.data?.asJson.alterName}!
          </p>
          <p>{t`We're continuously enhancing our system to provide you with the best experience. Our technical team is currently upgrading the system, which may temporarily affect some functions.`}</p>
          <p>{t`This process typically lasts no longer than 2 hours, after which the system will be fully functional again. Thank you for your patience!`}</p>
        </div>
        <SubmitButton
          label={t`Close`}
          className={classes.submitButton}
          disabled={false}
          isLoading={false}
          onSubmit={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default appWithStyles(styles)(MaintenancePageComponent);
