import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: _var(CSS_VARIABLES.appHeight),
      background: 'linear-gradient(180deg, #1B1629 0%, #000 100%)',
      width: '100%',
      display: 'flex',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      padding: '20px',
    },
    block: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up(Layout.laptop)]: {
        width: '50vh',
        height: '60vh',
      },
    },
    title: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      paddingBottom: '14px',
    },
    imageBlock: {
      marginTop: '16px',
    },
    image: {},
    text: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
    },
    submitButton: {},
  });
}
