import { appInject, appInjectable } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { TimeRangeType } from '@shared/enums/time-range-type.enum';
import { GetRateModel } from '@shared/models/rates/get-model';
import { Currency } from '@shared/models/wallets/currency';
import { IConfigService } from '@shared/types/config-service';
import { IDataResetHandler } from '@shared/types/data-reset-handler';
import { ILayoutVM } from '@shared/types/layout-service';
import { IOrdersService } from '@shared/types/orders-service';
import { IProductsVM } from '@shared/types/products-vm';
import { IRatesService } from '@shared/types/rates-service';
import { IRatesVM, Rate } from '@shared/types/rates-vm';
import { IStorageService, StorageType } from '@shared/types/storage-service';
import { priceForAmount } from '@shared/utils/metals';
import { roundNumber } from '@shared/utils/number';
import { getFirstSmartCoinCurrencyName } from '@shared/utils/products';
import { minutesToMilliseconds } from '@shared/utils/time';

const STORED_CURRENCY_KEY = 'STORED_CURRENCY_KEY';

type HistoricalMetalTokenPrice = { gr?: number; oz?: number; kg?: number };

@appInjectable()
export class RatesViewModel implements IRatesVM, IDataResetHandler {
  private layoutVM = appInject<ILayoutVM>(DI_TOKENS.layoutVM);
  private storageService = appInject<IStorageService>(DI_TOKENS.storageService);
  private ordersService = appInject<IOrdersService>(DI_TOKENS.ordersService);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private ratesService = appInject<IRatesService>(DI_TOKENS.ratesService);
  private _currency: Currency;
  private _ratesModel: GetRateModel = new GetRateModel({
    currency: Currency.USDT,
    lastUpdatedDate: '',
    product: Currency.GSC,
    priceInSystemBaseCurrency: 0,
    kgPriceInSystemBaseCurrency: 0,
    ouncePriceInSystemBaseCurrency: 0,
    price: 0,
    kgPrice: 0,
    ouncePrice: 0,
    isSpot: false,
    isEmergencyEnabled: false,
  });
  private _historicalMetalTokenPrice = {
    data: {} as HistoricalMetalTokenPrice,
  };
  private _rate = {
    [Currency.USDT]: 0,
    [Currency.AUDT]: 0,
  };

  constructor() {
    this._currency = this.getStoredCurrency();
    this.updateRates();

    if (this.configService.ratesRefreshInterval > 0) {
      setInterval(() => {
        this.updateRates();
      }, minutesToMilliseconds(this.configService.ratesRefreshInterval));
    }

    appMakeObservable(this, {
      _currency: appObservable,
      _rate: appObservable,
      _ratesModel: appObservable,
      _historicalMetalTokenPrice: appObservable,
    });
  }

  get currency(): Currency {
    return this._currency;
  }

  set currency(value: Currency) {
    this.layoutVM.showGlobalLoading();
    this._currency = value;

    this.updateRates().then(() => {
      this.setStoredCurrency(this._currency);
      this.layoutVM.hideGlobalLoading();
    });
  }

  get rate(): Rate {
    return this._rate;
  }

  getGrRate(currency: Currency): number {
    if (currency === Currency.USDT) {
      return this._ratesModel.asJson.price;
    } else if (currency === Currency.AUDT) {
      return this._ratesModel.asJson.priceInSystemBaseCurrency;
    } else return this._ratesModel.asJson.price;
  }

  getOzRate(currency: Currency): number {
    if (currency === Currency.USDT) {
      return this._ratesModel.asJson.ouncePrice;
    } else if (currency === Currency.AUDT) {
      return this._ratesModel.asJson.ouncePriceInSystemBaseCurrency;
    } else return this._ratesModel.asJson.ouncePrice;
  }

  getKgRate(currency: Currency): number {
    if (currency === Currency.USDT) {
      return this._ratesModel.asJson.kgPrice;
    } else if (currency === Currency.AUDT) {
      return this._ratesModel.asJson.kgPriceInSystemBaseCurrency;
    } else return this._ratesModel.asJson.kgPrice;
  }

  getHistoricalGrRate(): number {
    return this._historicalMetalTokenPrice.data.gr || 0;
  }

  getHistoricalOzRate(): number {
    return this._historicalMetalTokenPrice.data.oz || 0;
  }

  getHistoricalKgRate(): number {
    return this._historicalMetalTokenPrice.data.kg || 0;
  }

  private setRate(currency: Currency, newValue: number) {
    this._rate = {
      ...this._rate,
      [currency]: roundNumber(newValue, 2),
    };
  }

  priceForGDCAmount(currency: Currency, amount: number): number {
    return priceForAmount(this.rate[currency] as number, amount);
  }

  updateRates = async () => {
    const [rateModel] = await Promise.all([
      this.ratesService.getRates(this._currency, Currency.GSC),
      this.fetchPrices(),
    ]);

    this._ratesModel = rateModel;
    this.setRate(this._currency, rateModel.asJson.price);
  };

  private async fetchPrices(): Promise<void> {
    const historicData = await this.ordersService.getHistoricalSpotPrices(
      TimeRangeType.Day,
      this.currency,
      getFirstSmartCoinCurrencyName(this.productsVM.products) as string,
    );
    this._historicalMetalTokenPrice.data = historicData.asJson.currentSpotPrices;
  }

  private getStoredCurrency = (): Currency => {
    const storedCurrency = this.storageService.get(StorageType.localStorage, STORED_CURRENCY_KEY);
    if (storedCurrency) {
      return storedCurrency as Currency;
    }
    return Currency.USDT;
  };

  private setStoredCurrency = (currency: Currency) => {
    this.storageService.set(StorageType.localStorage, STORED_CURRENCY_KEY, currency);
  };

  //Erase data protocol - happens on logout
  onDataReset = () => {
    this.storageService.remove(StorageType.localStorage, STORED_CURRENCY_KEY);
    this._currency = this.getStoredCurrency();
  };
}
